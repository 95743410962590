export default{
    namespaced: true,

    state: {
        newCode: '',
        loans: [],
        loan_installments: [],
        loadingLoan: false,
        loadingLoanInstallment: false,
    },
    getters: {
        newCode(state){
            return state.newCode;
        },
        loans(state){
            return state.loans;
        },
        loan_installments(state){
            return state.loan_installments;
        },
        loadingLoan(state){
            return state.loadingLoan;
        },
        loadingLoanInstallment(state){
            return state.loadingLoanInstallment;
        },
    },
    mutations: {
        addNewLoanCode(state, code) {
            state.newCode = code;
        },
        loans(state, loans){
            state.loans = loans;
        },
        loan_installments(state, loan_installments){
            state.loan_installments = loan_installments;
        },
        loadingLoan(state, isLoading){
            state.loadingLoan = isLoading;
        },
        loadingLoanInstallment(state, isLoading){
            state.loadingLoanInstallment = isLoading;
        },
    },
    actions: {
        async saveLoan(ctx, data){
            let returnData = {success: false};
            
            await axios.post(data.url, data.loan)
            .then(res => {
                this.dispatch('snackbar/success', res.data.message);
                returnData.success = true;
            })
            .catch(error => {
                if(error.response.status == 422){
                    let validation_errors = error.response.data;
                    returnData.validation_errors = validation_errors;
                    this.dispatch('snackbar/error', 'Form not validate'); 
                }else{
                    this.dispatch('snackbar/error', error); 
                }
            })

            return returnData;
        },
        async updateLoanInstallment(ctx, data){
            let returnData = {success: false};
            
            await axios.post('/update_loan_installment', data)
            .then(res => {
                this.dispatch('snackbar/success', res.data.message);
                returnData.success = true;
            })
            .catch(error => {
                if(error.response.status == 422){
                    let validation_errors = error.response.data;
                    returnData.validation_errors = validation_errors;
                    this.dispatch('snackbar/error', 'Form not validate'); 
                }else{
                    this.dispatch('snackbar/error', error); 
                }
            })

            return returnData;
        },
        async getNewLoanCode(ctx) {
            await axios.get('/new-loan-code')
            .then(res => {
                ctx.commit('addNewLoanCode', res.data);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })
        },
        async getLoans(ctx, {apiParams = {}, options = {}} = {}){
            ctx.commit('loadingLoan', true);

            let loans = await axios.post(`/get_loans`, apiParams)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            ctx.commit('loadingLoan', false);

            if(options.returnData) {
                return loans;
            }

            ctx.commit('loans', loans);
        },
        async getLoanStatements(ctx, data){
            
            let loans = await axios.post(`/get_loan_statements/${data.id}`, {data})
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            return loans;
        },
        async getLoanInstallments(ctx, {apiParams = {}, options = {}} = {}){
            ctx.commit('loadingLoanInstallment', true);

            let loans = await axios.post(`/get_loan_installments`, apiParams)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            ctx.commit('loadingLoanInstallment', false);

            if(options.returnData) {
                return loans;
            }

            ctx.commit('loan_installments', loans);
        },
        async deleteLoan(ctx, id) {
            await axios.post(`/delete_loan`, {id})
            .then(res => {
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });
        },
    }
}