export default {
    namespaced: true,

    state: () => {
        return {
            loadingBanks: false,
            banks: [],
        }
    },

    getters: {
        loadingBanks(state) {
            return state.loadingBanks;
        },

        banks(state) {
            return state.banks;
        },
    },

    mutations: {
        loadingBanks(state, isLoading) {
            state.loadingBanks = isLoading;
        },

        addBanks(state, banks) {
            state.banks = banks;
        },
    },

    actions: {
        async getBanks(ctx, { apiParams = {}, options = {} } = {}) {
            ctx.commit('loadingBanks', true);

            let banks = await axios.get(`/get_banks`)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })
            
            ctx.commit('loadingBanks', false);

            if(options.returnData) {
                return banks;
            }
            
            ctx.commit('addBanks', banks);
        },

        async saveBank(ctx, data) {
            let returnData = {isSuccess: false};

            await axios.post(data.url, data.bank)
            .then(res => {
                returnData.isSuccess = true;
                ctx.dispatch('getBanks');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                if(error.response.status == 422){
                    let validation_errors = error.response.data;
                    returnData.validation_errors = validation_errors;
                    this.dispatch('snackbar/error', 'Form not validate'); 
                }else{
                    this.dispatch('snackbar/error', error); 
                }
            });
            return returnData;
        },

        async deleteBank(ctx, id) {
            await axios.post(`/delete-bank`, {id})
            .then(res => {
                ctx.dispatch('getBanks');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });
        }
    }
}
