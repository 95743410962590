<template>
    <v-app>
        <div class="area">
            <ul class="circles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>

        <v-container fluid class="fill-height" style="z-index:1">
            <v-row dense align="center" justify="center">
                <v-col cols="12" md="8">
                    <v-row dense>
                        <v-col cols="12" md="7">
                            <v-card :elevation="4" height="400px" class="text-center pa-10">
                                <v-img contain max-width="300" max-height="100" :src="$store.state.host + company.logo" class="mt-10 mx-auto"></v-img>
                                <h1 class="mt-10" style="font-weight:normal">{{company.name}}</h1>
                                <p class="body-2">{{company.address}}</p>
                                <p class="caption mt-2">
                                    {{company.phone}}
                                    <br>
                                    {{company.email}}
                                </p>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="5">
                            <v-card :elevation="4" dark color="blue darken-4" height="400px" class="text-center px-8 py-10">
                                <v-icon x-large class="mt-8">mdi-account-circle</v-icon>
                                <h3 class="mt-2">LOGIN</h3>
                                <v-form ref="loginForm" @submit.prevent="login">
                                    <v-text-field 
                                        outlined 
                                        dense 
                                        hide-details
                                        light
                                        autofocus
                                        prepend-inner-icon="mdi-account"
                                        class="mt-4"
                                        prefix=" | "
                                        background-color="white"
                                        v-model="user.username"
                                        :rules="[() => !!user.username || '']"
                                    >
                                    </v-text-field>
                                    <v-text-field 
                                        outlined 
                                        dense 
                                        hide-details
                                        light
                                        prepend-inner-icon="mdi-key"
                                        class="mt-2"
                                        prefix=" | "
                                        background-color="white"
                                        type="password"
                                        v-model="user.password"
                                        :rules="[() => !!user.password || '']"
                                    >
                                    </v-text-field>
                                    <v-btn block type="submit" color="success" class="mt-4">Login</v-btn>
                                </v-form>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        <v-footer app dark color="#424242" class="pa-0" height="26px">
			<v-container fluid class="pa-0">
				<v-row>
					<v-col cols="10" class="py-0">
						<marquee direction="left" scrollamount="2" onmouseover="this.stop();" onmouseout="this.start();">
							<span class="caption"
								>{{linkup_api_content}}</span
							>
						</marquee>
					</v-col>
                    <v-col cols="2" class="py-0 pr-0" style="padding: 4px 0;background-color: #3e2e6b;color:white; margin-bottom: -1px;text-align: center;">
						<span style="font-size: 12px;">
                            Developed by <a href="http://linktechbd.com/" target="_blank" style="color: white;text-decoration: underline;font-weight: normal;">.</a>
                        </span>
					</v-col>
				</v-row>
			</v-container>
		</v-footer>
        <snackbar></snackbar>
        <titleComponent :title="'HR & Payroll Software | ' + company.name"></titleComponent>
    </v-app>
</template>

<script>
import snackbar from '../components/snackbar.component';
import titleComponent from '../components/title.component';
export default {
    components: {
		snackbar,
        titleComponent,
	},
    data: () => {
        return {
            user: {
                username: '',
                password: '',
            },
            linkup_api_content: '',
            company: {},
        }
    },
    async created () {
        this.linkup_api_content = await this.$store.dispatch('company/getLinkupApiContent');
        await this.$store.dispatch('company/getCompany');
        this.company = this.$store.getters['company/company'];
    },
    methods: {
        async login() {

            if(!this.$refs.loginForm.validate()){
                this.$store.dispatch('snackbar/error', 'Fill red marked fields');
                return;
            }

            let isSuccess = await this.$store.dispatch('user/login', this.user);
            if(isSuccess) {
                this.$router.push('/');
            }
        },

    }
};
</script>

<style scoped>
* {
    margin: 0px;
    padding: 0px;
}

.area {
    background: #4e54c8;
    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: 0;
}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}

.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}

@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}
</style>