export default {
    namespaced: true,

    state: () => {
        return {
            loadingCompany: false,
            company: localStorage.getItem('company') ? JSON.parse(localStorage.getItem('company')) : {},
        }
    },

    getters: {
        loadingCompany(state) {
            return state.loadingCompany;
        },

        company(state) {
            return state.company;
        },
    },

    mutations: {
        loadingCompany(state, isLoading) {
            state.loadingCompany = isLoading;
        },
        addCompany(state, company){
            state.company = company;
        }
    },

    actions: {
        async getCompany(ctx, returnData = false) {
            ctx.commit('loadingCompany', true);

            let company = await axios.post(`/get_company`)
            .then(res => {
                localStorage.setItem('company', JSON.stringify(res.data))
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })
            
            ctx.commit('loadingCompany', false);

            if(returnData) {
                return company;
            }
        },
        async updateCompany(ctx, {data = {}}) {
            let returnData = {isSuccess: false};

            await axios.post('/update_company', data)
            .then(res => {
                this.dispatch('snackbar/success', res.data.message);
                returnData.isSuccess = true;
            })
            .catch(error => {
                if(error.response.status == 422){
                    let validation_errors = error.response.data;
                    returnData.validation_errors = validation_errors;
                    this.dispatch('snackbar/error', 'Form not validate'); 
                }else{
                    this.dispatch('snackbar/error', error); 
                }
            });

            return returnData;
        },

        async getLinkupApiContent(ctx){
            let data = await axios.get('/get_linkup_api_content').then(res=>{
                return res.data;
            })
            .catch(error=>{
                this.dispatch('snackbar/error', error);
            });

            return data;
        }
    }
}
