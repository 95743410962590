export default {
    namespaced: true,

    state: () => {
        return {
            months: [],
        }
    },

    getters: {
        months(state) {
            return state.months;
        },
    },

    mutations: {
        addSalaryMonths(state, months) {
            state.months = months;
        },
    },

    actions: {
        async getSalaryMonths(ctx, {apiParams = {}, options = {}} = {}) {

            let months = await axios.post(`/get_months`, apiParams)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            if(options.returnData) {
                return months;
            }

            ctx.commit('addSalaryMonths', months);
        },

        async saveMonth(ctx, data) {
            let returnData = {isSuccess: false};

            await axios.post(data.url, data.month)
            .then(res => {
                returnData.isSuccess = true;
                ctx.dispatch('getSalaryMonths');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                if(error.response.status == 422){
                    let validation_errors = error.response.data;
                    returnData.validation_errors = validation_errors;
                    this.dispatch('snackbar/error', 'Form not validate'); 
                }else{
                    this.dispatch('snackbar/error', error); 
                }
            });

            return returnData;
        },

        async deleteSalaryMonth(ctx, id) {
            await axios.post(`/delete-month`, {id})
            .then(res => {
                ctx.dispatch('getSalaryMonths');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });
        }
    }
}
