import moment from "moment/moment";

export default {
    filters: {
        booleanToText(value) {
            return value == true ? 'Yes' : 'No';
        },
        timeFormat(time){
            return time != null && time != '' ? moment(time,'hh:mm').format('hh:mm a') : '';
        },
        dateFormat(date, format = 'DD-MM-YYYY'){
            if(date){
                return moment(date).format(format);
            }
        }
    },
    methods: {
        toISOLocal(d = new Date()) {
            var z  = n =>  ('0' + n).slice(-2);
            var zz = n => ('00' + n).slice(-3);
            var off = d.getTimezoneOffset();
            var sign = off > 0? '-' : '+';
            off = Math.abs(off);
          
            return d.getFullYear() + '-'
                   + z(d.getMonth()+1) + '-' +
                   z(d.getDate()) + 'T' +
                   z(d.getHours()) + ':'  + 
                   z(d.getMinutes()) + ':' +
                   z(d.getSeconds()) + '.' +
                   zz(d.getMilliseconds()) +
                   sign + z(off/60|0) + ':' + z(off%60); 
        },
        monthNameArray(){
            return [
                "January", 
                "February", 
                "March", 
                "April", 
                "May", 
                "June",
                "July", 
                "August", 
                "September", 
                "October", 
                "November", 
                "December"
            ];
        },
    },
}