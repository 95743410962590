<template>
    <div>
        <v-card class="sales-scroll-card">
            <v-row>
                <v-col cols="1" class="py-0 pl-0 success text-center" style="color: #fff;">
                    <strong>Notice:</strong>
                </v-col>
                <v-col cols="11" class="py-0">
                    <marquee behavior="" direction="" style="font-size:14px">
                        <span v-for="(data, sl) in notices" :key="sl">
                            <span style="color:green;">({{data.created_at | dateFormat}})&nbsp;</span>{{data.notice}} <span v-if="notices.length - 1 > sl">&nbsp;|&nbsp;</span>
                        </span>
                    </marquee>
                </v-col>
            </v-row>
        </v-card>

        <v-container fluid>
            <v-row no-gutters>
                <v-col cols="12" md="1">
                </v-col>
                <v-col cols="12" md="2">
                    <v-card class="widget widget-1">
                        <div class="widget-icon">
                            <v-icon>mdi-account</v-icon>
                        </div>
                        <div class="widget-text">Total Employee</div>
                        <div class="widget-value">{{today_data.total_employee}}</div>
                    </v-card>
                </v-col>
                <v-col cols="12" md="2">
                    <v-card class="widget widget-2">
                        <div class="widget-icon">
                            <v-icon>mdi-fingerprint</v-icon>
                        </div>
                        <div class="widget-text">Present</div>
                        <div class="widget-value">{{today_data.present}}</div>
                    </v-card>
                </v-col>
                <v-col cols="12" md="2">
                    <v-card class="widget widget-3">
                        <div class="widget-icon">
                            <v-icon>mdi-clock-time-seven-outline</v-icon>
                        </div>
                        <div class="widget-text">Late</div>
                        <div class="widget-value">{{today_data.late}}</div>
                    </v-card>
                </v-col>
                <v-col cols="12" md="2">
                    <v-card class="widget widget-4">
                        <div class="widget-icon">
                            <v-icon>mdi-account-arrow-right-outline</v-icon>
                        </div>
                        <div class="widget-text">Leave</div>
                        <div class="widget-value">{{today_data.leave}}</div>
                    </v-card>
                </v-col>
                <v-col cols="12" md="2">
                    <v-card class="widget widget-5">
                        <div class="widget-icon">
                            <v-icon>mdi-fingerprint-off</v-icon>
                        </div>
                        <div class="widget-text">Absent</div>
                        <div class="widget-value">{{today_absent}}</div>
                    </v-card>
                </v-col>
                <v-col cols="12" md="1">
                </v-col>
            </v-row>

            <v-row class="mt-4">
                <v-col cols="12">
                    <h3 class="text-center">Attendances</h3>
                    <GChart
                        type="ColumnChart"
                        :data="chartData"
                        :options="chartOptions"
                    ></GChart>
                </v-col>
            </v-row>
            <v-row>
            <v-col cols="12" md="2" offset-md="5">
                <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    v-model="menu"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            dense
                            outlined
                            hide-details
                            v-model="month"
                            label="Month"
                            v-on="on"
                            class="custom-form"
                        ></v-text-field>
                    </template>
                    <v-date-picker type="month" v-model="month" @input="monthInput"></v-date-picker>
                </v-menu>
            </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="4">
                    <v-simple-table
                    dense
                    class="record_table"
                    >
                        <tr class="record_heading_tr">
                            <th colspan="4" style="text-align: center;">Late List</th>
                        </tr>
                        <tr v-for="(data, sl) in late_list" :key="sl">
                            <td>{{data.emp_id}}</td>
                            <td>{{data.employee_name}}</td>
                            <td>{{data.designation_name}}</td>
                            <td>{{data.late_time}}</td>
                        </tr>
                    </v-simple-table>
                    
                    <div 
                    style="float: right;" 
                    v-if="late_list.length > 0"
                    >    
                        <v-btn
                        tile
                        x-small
                        color="info"
                        to="/late_list"
                        class="white--text"
                        >
                        View
                        </v-btn>
                    </div>
                </v-col>

                <v-col cols="12" md="4">
                    <v-simple-table
                    dense
                    class="record_table"
                    >
                        <tr class="record_heading_tr">
                            <th colspan="4" style="text-align: center;">Leave List</th>
                        </tr>
                        <tr v-for="(data, sl) in leave_list" :key="sl">
                            <td>{{data.emp_id}}</td>
                            <td>{{data.employee_name}}</td>
                            <td>{{data.designation_name}}</td>
                            <td>{{data.leave_type_name}}</td>
                        </tr>
                    </v-simple-table>

                    <div 
                    style="float: right;" 
                    v-if="leave_list.length > 0"
                    >    
                        <v-btn
                        tile
                        x-small
                        color="info"
                        to="/leave_list"
                        class="white--text"
                        >
                        View
                        </v-btn>
                    </div>
                </v-col>

                <v-col cols="12" md="4">
                    <v-simple-table
                    dense
                    class="record_table"
                    >
                        <tr class="record_heading_tr">
                            <th colspan="3" style="text-align: center;">Absent List</th>
                        </tr>
                        <tr v-for="(data, sl) in absent_list" :key="sl">
                            <td>{{data.emp_id}}</td>
                            <td>{{data.employee_name}}</td>
                            <td>{{data.designation_name}}</td>
                        </tr>
                    </v-simple-table>

                    <div 
                    style="float: right;" 
                    v-if="absent_list.length > 0"
                    >    
                        <v-btn
                        tile
                        x-small
                        color="info"
                        to="/absent_list"
                        class="white--text"
                        >
                        View
                        </v-btn>
                    </div>
                </v-col>
            </v-row>

            <!-- <v-row class="mt-4 pie-charts">
                <v-col cols="12" md="6">
                    <h3 class="text-center">Top Present</h3>
                    <GChart
                        type="PieChart"
                        :data="topPresents"
                    ></GChart>
                </v-col>
                <v-col cols="12" md="6">
                    <h3 class="text-center">Top Absent</h3>
                    <GChart
                        type="PieChart"
                        :data="topAbsents"
                    ></GChart>
                </v-col>
            </v-row> -->

        </v-container>
    </div>
</template>
  
<script>
import { GChart } from 'vue-google-charts'
import utility from '../mixins/utility.mixin';
export default {
    name: 'Dashboard',
    mixins: [utility],
    components: {
        GChart
    },
    data () {
        return {
            chartData: [
                ['Date', 'Present', 'Late', 'Absent']
            ],
            chartOptions : {
                chart: {
                    title: 'Today\'s Attendance',
                    subtitle: 'Present, Late, and Absent',
                }
            },
            // topPresents: [
            //     ['Employee', 'Day'],
            //     ['FM Pliers', 25],
            //     ['Cutter Blade', 24],
            //     ['FM Plier', 24],
            //     ['FM Pilier', 23],
            //     ['FM Pliers', 23],
            // ],
            // topAbsents: [
            //     ['Employee', 'Day'],
            //     ['Al Modin', 3],
            //     ['Hewitt Cook', 2],
            //     ['Bangla', 1],
            //     ['Cylon Plc', 1],
            //     ['Jewel', 1],
            // ],
            today     : this.toISOLocal().substr(0, 10),
            month     : this.toISOLocal().substr(0, 7),
            menu      : false,
            today_data: {
                total_employee: 0,
                present       : 0,
                late          : 0,
                leave         : 0,
            },
            late_list  : [],
            absent_list: [],
            leave_list : [],
            notices    : [],
            
        }
    },
    computed: {
        today_absent(){
            return +this.today_data.total_employee - (+this.today_data.present + +this.today_data.leave);
        }
    },
    created () {
        this.getNotices();
        this.getTodayData();
        this.getMonthData();
        this.getLateList();
        this.getLeaveList();
        this.getAbsentList();
    },
    methods: {
        async getNotices(){
            this.notices = await this.$store.dispatch('notice/getNotices', true);
        },
        async getLeaveList(){
            this.leave_list = await this.$store.dispatch("leave/getLeaveList",{
                apiParams :
                {
                    date_from: this.today,
                    date_to: this.today,
                    limit: 10,
                    random: true,
                },
                options: {
                    returnData: true,
                }
            });
        },
        async getLateList(){
            this.late_list = await this.$store.dispatch("attendance/getAttendanceList",{
                apiParams :
                {
                    date_from: this.today,
                    date_to: this.today,
                    limit: 10,
                    random: true,
                    status: 'L',
                },
                options: {
                    returnData: true,
                }
            }); 
        },
        async getAbsentList(){
            let data = await this.$store.dispatch("attendance/getAbsentList",{
                apiParams :
                {
                    date_from: this.today,
                    date_to: this.today,
                    limit: 10,
                    random: true,
                },
                options: {
                    returnData: true,
                }
            }); 
            if(data.length > 0){
                this.absent_list = data[0].records;
            }
        },
        getTodayData(){
            axios.get('/get_employee_today_data').then(res=>{
                this.today_data = res.data;
            })
        },
        getMonthData(){
            axios.post('/get_month_data', {month: this.month}).then(res=>{
                this.chartData = [
                    ['Date', 'Present', 'Late', 'Absent']
                ];
                res.data.forEach(d => {
                    this.chartData.push(d);
                })
            })
        },
        monthInput(){
            this.menu = false;
            this.getMonthData();
        }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .pie-charts{
      text {
          font-size: 12px;
      }
  }
  .sales-scroll-card {
      border-radius: unset !important;
    //   background: #263238 !important;
      color: rgb(163, 37, 37) !important;
      padding: 0 4px;
      margin-top: 10px;
  }
  .widget {
      margin: 15px 10px;
      text-align: center;
  
      .widget-icon {
          width: 40px;
          height: 40px;
          border-radius: 50% !important;
          margin: auto;
          padding-top: 8px;
          margin-top: 8px;
          margin-bottom: 3px;
          
          .v-icon {
              color: #fff;
          }
      }
  
      .widget-text {
          font-size: 13px;
          font-weight: bold;
          color: rgb(94, 94, 94);
          letter-spacing: .5px;
      }
  
      .widget-value {
          font-weight: bold;
          color: rgb(53, 53, 53);
      }
  }
  
  .widget-1 {
      border-top: 5px solid #1C8DFF !important;
      .widget-icon { background: #1C8DFF }
  }
  .widget-2 {
      border-top: 5px solid #666633 !important;
      .widget-icon { background: #666633 }
  }
  .widget-3 {
      border-top: 5px solid #008241 !important;
      .widget-icon { background: #008241 }
  }
  .widget-4 {
      border-top: 5px solid #FF8000 !important;
      .widget-icon { background: #FF8000 }
  }
  .widget-5 {
      border-top: 5px solid #AE0000 !important;
      .widget-icon { background: #AE0000 }
  }
  .widget-6 {
      border-top: 5px solid #663300 !important;
      .widget-icon { background: #663300 }
  }

.record_table table,
.record_table th,
.record_table td {
    border: 1px solid #dee2e6;
    border-collapse: collapse;
    padding: 0 !important;
}

.record_table td {
  padding: 2px !important;
  font-size: 11px !important;
  text-align: center;
  height: 0 !important;
}

.record_heading_tr{
    background: #000;
    color: #fff;
}
  </style>