export default {
    namespaced: true,

    state: () => {
        return {
            devices: [],
        }
    },

    getters: {
        devices(state) {
            return state.devices;
        },
    },

    mutations: {
        addDevices(state, devices){
            state.devices = devices;
        }
    },

    actions: {
        async getDevices(ctx, returnData = false) {
            let devices = await axios.post(`/get_devices`)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            if(returnData) {
                return devices;
            }

            ctx.commit('addDevices', devices);
        },
    }
}
