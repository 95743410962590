export default{
    namespaced: true,

    state: {
        increments: [],
        loadingIncrement: false,
    },
    getters: {
        increments(state){
            return state.increments;
        },
        loadingIncrement(state){
            return state.loadingIncrement;
        }
    },
    mutations: {
        increments(state, increments){
            state.increments = increments;
        },
        loadingIncrement(state, isLoading){
            state.loadingIncrement = isLoading;
        }
    },
    actions: {
        async saveIncrement(ctx, data){
            let returnData = {success: false};
            
            await axios.post(data.url, data.increment)
            .then(res => {
                this.dispatch('snackbar/success', res.data.message);
                returnData.success = true;
            })
            .catch(error => {
                if(error.response.status == 422){
                    let validation_errors = error.response.data;
                    returnData.validation_errors = validation_errors;
                    this.dispatch('snackbar/error', 'Form not validate'); 
                }else{
                    this.dispatch('snackbar/error', error); 
                }
            })

            return returnData;
        },
        async getIncrements(ctx, {apiParams = {}, options = {}} = {}){
            ctx.commit('loadingIncrement', true);

            let increments = await axios.post(`/get_increments`, apiParams)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            ctx.commit('loadingIncrement', false);

            if(options.returnData) {
                return increments;
            }

            ctx.commit('increments', increments);
        },
        async deleteIncrement(ctx, id) {
            await axios.post(`/delete_increment`, {id})
            .then(res => {
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });
        }
    }
}