export default {
    namespaced: true,

    state: () => {
        return {
            weekends: [],
        }
    },

    getters: {
        weekends(state) {
            return state.weekends;
        },
    },

    mutations: {
        addWeekends(state, weekends) {
            state.weekends = weekends;
        },
    },

    actions: {
        async getWeekends(ctx, { apiParams = {}, options = {} } = {}) {

            let weekends = await axios.get(`/get_weekends`)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })
            
            if(options.returnData) {
                return weekends;
            }
            
            ctx.commit('addWeekends', weekends);
        },

        async updateWeekends(ctx, data) {
            
            let returnData = {isSuccess: false};

            await axios.post(data.url, data.weekends)
            .then(res => {
                returnData.isSuccess = true;
                ctx.dispatch('getWeekends');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });

            return returnData;
        },
    }
}
