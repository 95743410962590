export default {
    namespaced: true,

    state: () => {
        return {
            loadingCountries: false,
            countries: [],
        }
    },

    getters: {
        loadingCountries(state) {
            return state.loadingCountries;
        },

        countries(state) {
            return state.countries;
        },
    },

    mutations: {
        loadingCountries(state, isLoading) {
            state.loadingCountries = isLoading;
        },

        addCountries(state, countries) {
            state.countries = countries;
        },
    },

    actions: {
        async getCountries(ctx) {
            ctx.commit('loadingCountries', true);

            let countries = await axios.get(`/get_countries`)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            ctx.commit('addCountries', countries);

            ctx.commit('loadingCountries', false);
        },

        async saveCountry(ctx, data) {

            let returnData = {isSuccess: false};

            await axios.post(data.url, data.country)
            .then(res => {
                returnData.isSuccess = true;
                ctx.dispatch('getCountries');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                if(error.response.status == 422){
                    let validation_errors = error.response.data;
                    returnData.validation_errors = validation_errors;
                    this.dispatch('snackbar/error', 'Form not validate'); 
                }else{
                    this.dispatch('snackbar/error', error); 
                }
            });
            return returnData;
        },

        async deleteCountry(ctx, id) {
            await axios.post(`/delete-country`,{id})
            .then(res => {
                ctx.dispatch('getCountries');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });
        }
    }
}
