export default{
    namespaced: true,

    state: {
        newCode     : '',
        transactions: [],
        pf_balances : [],
    },
    getters: {
        newCode(state){
            return state.newCode;
        },
        transactions(state){
            return state.transactions;
        },
        pf_balances(state){
            return state.pf_balances;
        },
    },
    mutations: {
        addNewTransactionCode(state, code) {
            state.newCode = code;
        },
        transactions(state, transactions){
            state.transactions = transactions;
        },
        pf_balances(state, pf_balances){
            state.pf_balances = pf_balances;
        },
    },
    actions: {
        async saveTransaction(ctx, data){
            let returnData = {success: false};
            
            await axios.post(data.url, data.transaction)
            .then(res => {
                this.dispatch('snackbar/success', res.data.message);
                returnData.success = true;
            })
            .catch(error => {
                if(error.response.status == 422){
                    let validation_errors = error.response.data;
                    returnData.validation_errors = validation_errors;
                    this.dispatch('snackbar/error', 'Form not validate'); 
                }else{
                    this.dispatch('snackbar/error', error); 
                }
            })

            return returnData;
        },
        async getNewTransactionCode(ctx) {
            await axios.get('/new-pf-transaction-code')
            .then(res => {
                ctx.commit('addNewTransactionCode', res.data);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })
        },
        async getTransactions(ctx, {apiParams = {}, options = {}} = {}){

            let transactions = await axios.post(`/get_pf_transactions`, apiParams)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            if(options.returnData) {
                return transactions;
            }
            
            ctx.commit('transactions', transactions);
        },
        async deleteTransaction(ctx, id) {
            await axios.post(`/delete_pf_transaction`, {id})
            .then(res => {
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });
        },

        async getPFBalances(ctx, { apiParams = {}, options = {} } = {}) {
            let records = await axios.post(`/get_pf_balances`, apiParams)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            if(options.returnData) {
                return records;
            }

            ctx.commit('pf_balances', records);
        },
        async getPFLedger(ctx, data){
            
            let result = await axios.post(`/get_pf_ledger/${data.id}`, {data})
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            return result;
        },
    }
}