export default{
    namespaced: true,

    state: {
        bonuses      : [],
        bonus_details: [],
    },
    mutations: {
        bonuses(state, bonuses){
            state.bonuses = bonuses;
        },
        bonus_details(state, bonus_details){
            state.bonus_details = bonus_details;
        },
    },
    getters: {
        bonuses(state){
            return state.bonuses;
        },
        bonus_details(state){
            return state.bonus_details;
        },
    },
    actions: {
        async generateBonus(ctx, data){
            let returnData = {success: false};
            await axios.post(data.url, {bonus: data.bonus, details: data.details})
            .then(res=>{
                returnData.success = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error=>{
                if(error.response.status == 422){
                    let validation_errors = error.response.data;
                    returnData.validation_errors = validation_errors;
                    this.dispatch('snackbar/error', 'Form not validate'); 
                }else{
                    this.dispatch('snackbar/error', error); 
                }
            })

            return returnData;
        },
        async getBonuses(ctx, {apiParams = {}, options = {}} = {}){

            let bonuses = await axios.post(`/get_bonuses`, apiParams)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })


            if(options.returnData) {
                return bonuses;
            }

            ctx.commit('bonuses', bonuses);
        },
        async getBonusDetails(ctx, {apiParams = {}, options = {}} = {}){

            let details = await axios.post(`/get_bonus_details`, apiParams)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })


            if(options.returnData) {
                return details;
            }

            ctx.commit('bonus_details', details);
        },

        async getBonusStatements(ctx, {apiParams = {}, options = {}} = {}){
            let statements = await axios.post('/get_bonus_statements', apiParams)
            .then(res=>{
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            if(options.returnData){
                return statements;
            }
        },
    }
}